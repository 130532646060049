import { all, fork } from 'redux-saga/effects';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import signupReducer from '../pages/Signup/redux/signupReducer';
import forgotPasswordReducer from '../pages/Forgot-password/redux/forgotPasswordReducer';

import signupSaga from '../pages/Signup/redux/signupSaga';

const rootReducer = combineReducers({
  signupManagement: signupReducer,
  forgotPassword: forgotPasswordReducer
});

function* rootSaga() {
  yield all([
    // Home page
    yield all(signupSaga.map((s) => fork(s)))
  ]);
}

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  return store;
}

export default configureStore;
