export class LocalStorageConstants {
  static badInfo = 'badInfo';
  static badUid = 'bad_uid';
  static hashKey = 'hash_key';
  static contactUid = 'contact_uid';
  static ipAddress = 'ip_address';
  static signInDetails = 'signin_info';
  static badUuid = 'bad-uid';
  static signinBadUuid = 'bad_uuid';
  static ownerShipUid = 'ownership_uid';
  static expiry = 'expiry';
}

export const badInfoObject = {
  bad_uid: '',
  hash_key: '',
  contact_uid: ''
};

export class OsPlateforms {
  static windows = 'windows';
  static android = 'android';
  static ios = 'ios';
  static mac = 'mac';
}
