export const signUpErrorHandler = (err) => {
  let errorMsg;
  try {
    errorMsg = JSON.parse(err.message);
  } catch (e) {
    errorMsg = err.message;
  }
  let newErrorMessage = [];
  if (Array.isArray(errorMsg)) {
    newErrorMessage = errorMsg.filter((el) => el);
  } else {
    newErrorMessage.push(errorMsg);
  }
  return { ...err, message: newErrorMessage };
};
