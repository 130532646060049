export const routerPaths = {
  SIGN_IN: '/signin',

  SIGN_UP: '/',
  EMAIL_VERIFICATION: '/email_verification',
  ENTER_MOBILE_NUMBER: '/enter_mobile_number',
  MOBILE_VERIFICATION: '/mobile_verification',
  SIGNUP_SPLASH_SCREEN: '/splash_screen',
  TERM_AND_CONDITIONS: '/term_and_conditions',
  LEGAL_PRIVACY_POLICY: '/legal_privacy_policy',

  ADD_BROWSER_AS_DEVICE: '/add-browser-as-device',

  FORGOT_PASSWORD_ENTER_EMAIL: '/forgot_password',
  FORGOT_PASSWORD_EMAIL_SENT: '/forgot_password/email_sent',
  FORGOT_PASSWORD_ENTER_OTP: '/forgot_password/enter_otp',
  FORGOT_PASSWORD_SET_NEW_PASSWORD: '/forgot_password/set_new_password',

  Re_REGISTRATION: '/re_registration',
  Re_REGISTRATIONAPPROVED: '/re_registration/approved',
  Add_OWNER_DEVICE: '/add/owner/device',
  HOME: '/home',

  DOWNLOAD: '/download',

  ALLOW_REGISTER: '/allow-register'
};
