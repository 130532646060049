import { OsPlateforms } from './constants';
import axios from 'axios';

export const getIpAddress = async () => {
  // try {
  const baseURL = 'https://ip.ticca.com/';

  const res = await axios.get(baseURL);

  return res.data ?? '';
  // } catch (err) {
  //   return '49.36.88.154';
  // }
};
// const plateformsList = {
//   windows: ['OS/2', 'Pocket PC', 'Windows', 'Win16', 'Win32', 'Win64', 'WinCE'],
//   android: ['Android', 'Linux', 'null', 'Linux armv7l', 'Linux aarch64'],
//   ios: [
//     'iPhone',
//     'iPad',
//     'iPod',
//     'iPhone Simulator',
//     'Pike v7.6 release 92',
//     'Pike v7.8 release 517'
//   ],
//   mac: ['MacIntel', 'Mac', 'Macintosh', 'MacPPC', 'Mac68K', 'MacIntel']
// };

const osPlateformsList = [
  {
    plateform: 'OS/2',
    os: OsPlateforms.windows
  },
  {
    plateform: 'Pocket PC',
    os: OsPlateforms.windows
  },
  {
    plateform: 'Windows',
    os: OsPlateforms.windows
  },
  {
    plateform: 'Win16',
    os: OsPlateforms.windows
  },
  {
    plateform: 'Win32',
    os: OsPlateforms.windows
  },
  {
    plateform: 'Win64',
    os: OsPlateforms.windows
  },
  {
    plateform: 'WinCE',
    os: OsPlateforms.windows
  },
  {
    plateform: 'Android',
    os: OsPlateforms.android
  },
  {
    plateform: 'Linux',
    os: OsPlateforms.android
  },
  {
    plateform: 'null',
    os: OsPlateforms.android
  },
  {
    plateform: 'Linux armv7l',
    os: OsPlateforms.android
  },
  {
    plateform: 'Linux aarch64',
    os: OsPlateforms.android
  },
  {
    plateform: 'undefined',
    os: OsPlateforms.android
  },
  {
    plateform: 'Linux aarch64',
    os: OsPlateforms.android
  },
  {
    plateform: 'iPhone',
    os: OsPlateforms.ios
  },
  {
    plateform: 'iPad',
    os: OsPlateforms.ios
  },
  {
    plateform: 'iPod',
    os: OsPlateforms.ios
  },
  {
    plateform: 'iPhone Simulator',
    os: OsPlateforms.ios
  },
  {
    plateform: 'Pike v7.6 release 92',
    os: OsPlateforms.ios
  },
  {
    plateform: 'Pike v7.8 release 517',
    os: OsPlateforms.ios
  },
  {
    plateform: 'MacIntel',
    os: OsPlateforms.mac
  },
  {
    plateform: 'Mac',
    os: OsPlateforms.mac
  },
  {
    plateform: 'Macintosh',
    os: OsPlateforms.mac
  },
  {
    plateform: 'MacPPC',
    os: OsPlateforms.mac
  },
  {
    plateform: 'Mac68K',
    os: OsPlateforms.mac
  },
  {
    plateform: 'MacIntel',
    os: OsPlateforms.mac
  }
];

// Returns os of system
export const getSystemOs = async () => {
  const plateform = navigator?.platform ?? navigator?.userAgent;

  let os = '';

  for (let item of osPlateformsList) {
    if (plateform.includes(item.plateform)) {
      os = item.os;
    }
  }

  return os;
};
