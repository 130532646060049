export const signupActionTypes = {
  SIGN_UP: 'dashboard/signup/signup',

  SIGN_UP_SUCCESS: 'dashboard/signup/signup_success',
  SIGN_UP_FAILED: 'dashboard/signup/signup_failed',
  SIGN_UP_REQUEST_END: 'dashboard/signup/fetch_success',

  EMAIL_VERIFICATION: 'dashboard/signup/email_verification',
  EMAIL_VERIFICATION_SUCCESS: 'dashboard/signup/email_verification_success',
  EMAIL_VERIFICATION_FAILED: 'dashboard/signup/email_verification_failed',

  RESEND_EMAIL_VERIFICATION: 'dashboard/signup/resend_email_verification',
  RESEND_EMAIL_VERIFICATION_SUCCESS: 'dashboard/signup/resend_email_verification_success',
  RESEND_EMAIL_VERIFICATION_FAILED: 'dashboard/signup/resend_email_verification_failed',

  SET_TEMP_USER: 'dashboard/signup/set_temp_user',
  SET_FLOW_MANAGEMENT: 'dashboard/signup/set_flow_management'
};

export function signupAction(values, navigate) {
  return { type: signupActionTypes.SIGN_UP, values, navigate };
}

export function verifyEmail(values, navigate) {
  return { type: signupActionTypes.EMAIL_VERIFICATION, values, navigate };
}

export function resendEmail(values) {
  return { type: signupActionTypes.RESEND_EMAIL_VERIFICATION, values };
}

export function setTempUser(values) {
  return { type: signupActionTypes.SET_TEMP_USER, values };
}

export function setFlowManagement(values) {
  return { type: signupActionTypes.SET_FLOW_MANAGEMENT, values };
}

export function signupFailed(values) {
  let error = { message: values?.error?.message ?? [] };
  return { type: signupActionTypes.SIGN_UP_FAILED, error };
}
