import { Toast } from '../utils/toast';
export const setCoordinates = async (state, setState) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let lat = position.coords.latitude.toFixed(6).toString() ?? '0.0';
        let long = position.coords.longitude.toFixed(6).toString() ?? '0.0';
        setState({ ...state, longitude: long, latitude: lat });
      },
      (err) => {
        console.log("We can't access your current geolocation!", err);
        Toast.warning(
          "We can't access your current geolocation, please enable your location services for the best experience with us if your device supports location services."
        );
        setState({ ...state, longitude: '360', latitude: '360' });
      }
    );
  } else {
    setState({ ...state, longitude: '0.0', latitude: '0.0' });
  }
};
