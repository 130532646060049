import publicIp from 'public-ip';
import axios from 'axios';

export const jsonToForm = (values) => {
  let form_data = new FormData();
  for (let key in values) {
    form_data.append(key, values[key]);
  }
  return form_data;
};

export const getIpAddress = async () => {
  try {
    const baseURL = 'https://ip.ticca.com/';

    const res = await axios.get(baseURL);

    return res.data ?? '';
  } catch (err) {
    return '';
    // debugger;
  }
};
