import { signupActionTypes } from './signupActions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerPaths } from '../../../utils/routes';

const initialState = {
  tempUser: null,
  mainComponent: {
    loading: false,
    error: null
  },
  emailVerification: {
    loading: false,
    error: null,
    isSendingCode: false
  },
  mobileVerification: {
    loading: false,
    error: null
  },
  flowManagement: {
    currentFlowPath: routerPaths.DOWNLOAD,
    timestamp: new Date()
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case signupActionTypes.SIGN_UP:
      return {
        ...state,
        mainComponent: { ...state.mainComponent, loading: true }
      };
    case signupActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        mainComponent: { ...state.mainComponent, loading: false },
        tempUser: { ...state.tempUser, ...action.tempUser }
      };
    case signupActionTypes.SIGN_UP_FAILED: {
      return {
        ...state,
        mainComponent: { error: action?.error, loading: false }
      };
    }
    case signupActionTypes.SIGN_UP_REQUEST_END: {
      return {
        ...state,
        mainComponent: {
          ...state.mainComponent,
          loading: false
        },
        tempUser: { ...state.tempUser, ...action.tempUser }
      };
    }
    case signupActionTypes.EMAIL_VERIFICATION:
      return {
        ...state,
        emailVerification: { ...state.emailVerification, loading: true }
      };
    case signupActionTypes.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerification: { ...state.emailVerification, isSendingCode: false }
      };
    case signupActionTypes.EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        emailVerification: { error: action.error, isSendingCode: false }
      };
    case signupActionTypes.RESEND_EMAIL_VERIFICATION:
      return {
        ...state,
        emailVerification: { ...state.emailVerification, isSendingCode: true }
      };
    case signupActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerification: { ...state.emailVerification, isSendingCode: false, error: null }
      };
    case signupActionTypes.SET_TEMP_USER:
      return {
        ...state,
        tempUser: { ...state.tempUser, ...action.values }
      };
    case signupActionTypes.SET_FLOW_MANAGEMENT:
      return {
        ...state,
        flowManagement: { ...state.flowManagement, ...action.values }
      };
    default:
      return state;
  }
}

const persistConfig = {
  keyPrefix: 'dashboard-web-',
  key: 'auth',
  whitelist: ['tempUser', 'flowManagement'],
  storage
};

export default persistReducer(persistConfig, reducer);
