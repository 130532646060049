import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerPaths } from '../../../utils/routes';
import { forgotPasswordActionTypes } from './forgotPasswordActions';

const initialState = {
  flowManagement: {
    currentFlowPath: routerPaths.FORGOT_PASSWORD_ENTER_EMAIL,
    timestamp: new Date()
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case forgotPasswordActionTypes.SET_FLOW_MANAGEMENT:
      return {
        ...state,
        flowManagement: { ...state.flowManagement, ...action.values }
      };
    default:
      return state;
  }
}

const persistConfig = {
  keyPrefix: 'dashboard-web-',
  key: 'forgot-pass',
  whitelist: ['flowManagement'],
  storage
};

export default persistReducer(persistConfig, reducer);
