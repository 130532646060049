import axios from 'axios';

let env = process.env?.REACT_APP_STAGE_ENV;
let apiEndpoint = process.env.REACT_APP_TICCA_API_ENDPOINT;

if (env === 'dev' || process.env?.NODE_ENV === 'development') {
  apiEndpoint = process.env.REACT_APP_TICCA_API_DEV_ENDPOINT;
}

if (env === 'stage') {
  apiEndpoint = process.env.REACT_APP_TICCA_API_STAGE_ENDPOINT;
}

// Here we're creating the axios http request base
// if a user is already logged in we take the token and send it REACT_APP_DEV_API the Bearer Token

// This is for the register
export const Axios = axios.create({
  baseURL: `${apiEndpoint}/register`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'en',
    'Content-Language': 'en'
  }
});

// This is for the control apis
export const AxiosControlInstance = axios.create({
  baseURL: `${apiEndpoint}/control`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'en',
    'Content-Language': 'en'
  }
});

Axios.interceptors.request.use(
  async function (config) {
    const bearerToken = localStorage.getItem('bearerToken');
    config.headers.common['Authorization'] = `Bearer ${bearerToken}`;
    // config?.data?.append('form_uid', localStorage.getItem('contact_uid'));
    // config?.data?.append('hash_key', localStorage.getItem('hash_key'));
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    if (response?.data['bad_uuid']) {
      localStorage.setItem('bad_uuid', response.data['bad_uuid']);
    }
    // if (response?.data?.['bad-uuid']) {
    //   localStorage.setItem('bad-uid', response.data['bad-uuid']);
    // }
    if (response?.data['hash-key']) {
      localStorage.setItem('hash-key', response.data['hash-key']);
    }
    return response;
  },
  async function (error) {
    if (error.response) {
      const errorCode = error.response.status;
      if (errorCode === 401) {
        /*        if (window) {
                  if (window.location.pathname !== '/') {
                    window.location.href = '/';
                  }
                }*/
      }
    }
    return Promise.reject(error);
  }
);

// Axios controll apis
AxiosControlInstance.interceptors.request.use(
  async function (config) {
    const bearerToken = localStorage.getItem('bearerToken');
    config.headers.common['Authorization'] = `Bearer ${bearerToken}`;
    // config?.data?.append('form_uid', localStorage.getItem('bad-uid'));
    // config?.data?.append('hash_key', localStorage.getItem('hash-key'));
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosControlInstance.interceptors.response.use(
  function (response) {
    // if (response?.data['bad-uid']) {
    //   localStorage.setItem('bad-uid', response.data['bad-uid']);
    // }
    if (response?.data['hash-key']) {
      localStorage.setItem('hash-key', response.data['hash-key']);
    }
    return response;
  },
  async function (error) {
    if (error.response) {
      const errorCode = error.response.status;
      if (errorCode === 401) {
        /*        if (window) {
                  if (window.location.pathname !== '/') {
                    window.location.href = '/';
                  }
                }*/
      }
    }
    return Promise.reject(error);
  }
);
