/* eslint-disable prettier/prettier */

import React, { Children } from 'react';
import { Box } from '@mui/system';

import './LayoutWithBackground.scss';

const  LayoutWithBackground = ({ children, containerClass = '' }) => {
  return (
    <div className={`background-with-layout-container ${containerClass}`}>
      <Box className="background-layer background-blur-layer" />
      <Box className="signup-screen-background">
        <img src="/assets/backgrounds/signup-background.png" alt=" " />
      </Box>

      <div className="content-container">{children}</div>
    </div>
  );
};

export default LayoutWithBackground;
