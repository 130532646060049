import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
import { persistStore } from 'redux-persist';
import configureStore from './utils/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const store = configureStore();
const persistor = persistStore(store);

const theme = createTheme({
  palette: {
    blue: { main: '#ffd101', contrastText: 'black' },
    orange: { main: '#0E59DA', contrastText: 'white' },
    primary: {
      main: '#175DD7'
    }
  },
  typography: { fontFamily: ['Avenir'] },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {}
      }
    },
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {}
      }
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
        <App />
        <ToastContainer />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
