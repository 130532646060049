import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '100%',
    position: 'relative',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh'
  },
  formMain: {
    background: '#FDFDFD',
    maxWidth: '386px',
    width: '100%',
    minHeight: 660,
    borderRadius: 8,
    margin: '10px',
    position: 'relative',
    boxSizing: 'border-box',
    // display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 35px',
    [theme.breakpoints.down('md')]: {
      padding: '50px 25px'
    }
  },
  backgroundLayer: {
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    // backdropFilter: 'blur(2px)',
    ['@media (max-width: 600px)']: {
      display: 'none'
    }
  },
  servicesElementContainer: {
    position: 'absolute',
    width: 'auto',
    height: '100%',
    display: 'flex',
    top: 0,
    bottom: 0,
    justifyContent: 'end',
    alignItems: 'center',
    ['@media (max-width:1050px)']: {
      display: 'none !important'
    }
  },
  ticcaServicesElementsImage: {
    width: 'auto',
    height: '90%',
    objectFit: 'contain'
  },
  signupFormTitle: {
    textAlign: 'center',
    color: '#074EDC',
    fontSize: [35, '!important'],
    marginTop: { xs: '15px', md: '20px' }
  },
  signupFormSubtitle: {
    textAlign: 'center',
    color: '#000000',
    fontSize: [15, '!important'],
    marginTop: { xs: '15px', md: '20px' }
  },
  clientName: {
    textAlign: 'center',
    color: '#0E59DA',
    fontSize: [15, '!important'],
    paddingTop: '20px'
  },
  submitButton: {
    backgroundImage: 'linear-gradient(to right, #175DD7, #001984)',
    height: 44.59,
    borderRadius: [32, '!important'],
    textTransform: ['none', '!important'],
    fontSize: [18, '!important'],
    fontFamily: 'Poppins',
    color: ['white', '!important']
  },
  submitButtonDisabled: {
    backgroundImage: ['#D8D8D8', '!important'],
    color: ['#7F8596', '!important'],
    height: 44.59,
    borderRadius: [32, '!important'],
    textTransform: ['none', '!important'],
    fontSize: [18, '!important'],
    fontFamily: 'Poppins'
  },
  resendEmail: {
    color: '#0E59DA',
    textAlign: 'center',
    marginTop: ['7.2px', '!important'],
    fontSize: { xs: 12, md: 15 },
    fontWeight: [100, '!important'],
    marginLeft: ['5px'],
    cursor: 'pointer',
    '& span': {
      fontWeight: 'bold'
    }
  },
  phoneNumber: {
    color: '#F8782B',
    textAlign: 'center',
    marginTop: ['7.2px', '!important'],
    fontSize: { xs: 12, md: 15 },
    fontWeight: [300, '!important'],
    cursor: 'pointer',
    '& span': {
      fontWeight: 'bold'
    }
  },
  countdownText: {
    fontWeight: [100, '!important'],
    color: 'black',
    textAlign: 'center',
    fontSize: ['14px', '!important']
  },
  versionTheme: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '25px',
    marginTop: { xs: '15px', md: '20px' }
  }
}));
