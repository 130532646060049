import React from 'react';
import { Box, Hidden } from '@mui/material';
import { useStyles } from '../styles';
import './SignUpLayout.scss';
import { Link } from 'react-router-dom';
import { routerPaths } from '../../../utils/routes';

function SignUpLayout({ children }) {
  const classes = useStyles();

  return (
    <Box className={`${classes.formContainer} signup-layout-container`}>
      <Box className={`${classes.formMain} form-container`}>
        {/* <img
          src={'/assets/signup/water mark.png'}
          style={{ position: 'absolute', top: 120, left: 6 }}
        />
        <img
          src={'/assets/signup/water mark.png'}
          className="water-mark-middle-image"
          style={{ position: 'absolute', top: 320, right: 0 }}
        />
        <img
          src={'/assets/signup/water mark.png'}
          style={{ position: 'absolute', bottom: 0, left: 0 }}
        /> */}
        <Link to={routerPaths.SIGN_UP} style={{ color: 'inherit' }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={'/assets/ticca-logo-3.svg'} />
          </Box>
        </Link>
        {children}
      </Box>
    </Box>
  );
}

export default SignUpLayout;
