import './App.css';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { routerPaths } from './utils/routes';
import AddOwnerDevice from './pages/Signup/flow/AddOwnerDevice';
import { ExternalDynamicLinks } from './helpers/urlHelper';

// Page routes
const Signin = lazy(() => import('./pages/Signin/SignIn'));
const EmailVerification = lazy(() => import('./pages/Signup/flow/EmailVerification'));
const MobileVerification = lazy(() => import('./pages/Signup/flow/MobileVerification'));
const SplashScreen = lazy(() => import('./pages/Signup/flow/SplashScreen'));
const EnterMobileNumber = lazy(() => import('./pages/Signup/flow/EnterMobileNumber'));
const MainComponent = lazy(() => import('./pages/Signup/flow/MainComponent'));
const ForgotPassword = lazy(() => import('./pages/Forgot-password/flow/ForgotPassword'));
const EmailSent = lazy(() => import('./pages/Forgot-password/flow/EmailSent'));
const EnterOTP = lazy(() => import('./pages/Forgot-password/flow/EnterOTP'));
const SetNewPassword = lazy(() => import('./pages/Forgot-password/flow/SetNewPassword'));
const ReRegistration = lazy(() => import('./pages/Re-Registration/ReRegistration'));
const ReRegistrationApproved = lazy(() => import('./pages/Re-Registration/ReRegjstrationApproved'));
const TermAndConditions = lazy(() => import('./pages/TermAndConditions/TermAndConditions'));
const LegalPrivacyPolicy = lazy(() => import('./pages/LegalPrivacyPolicy/LegalPrivacyPolicy'));

const Home = lazy(() => import('./pages/Home/Home'));
// const SignUpFlowRoute = lazy(() => import('./pages/Signup/SignUpFlowRoute'));
// const ForgotPasswordFlowRoute = lazy(() =>
//   import('./pages/Forgot-password/ForgotPasswordFlowRoute')
// );
const DownloadPage = lazy(() => import('./pages/DownloadPage/DownloadPage'));
const AllowRegister = lazy(() => import('./pages/AllowRegister/AllowRegister'));

function App() {
  const handleMessageFromSignupWeb = (event) => {
    const allowedOrigins = [ExternalDynamicLinks.dashboard()];
    // console.log('event', event);
    if (allowedOrigins.includes(event.origin)) {
      const data = event.data;
      if (data) {
        const msgData = JSON.parse(data);
        // console.log('msgData', msgData?.ownershipuid);
        const ownershipuid = msgData?.ownershipuid;
        const hashKey = msgData?.hashKey;

        localStorage.setItem('ownership_uid', ownershipuid);
        localStorage.setItem('hash_key', hashKey);

        // AuthLocalStorageServices.tokens.setBadUuid(badUuid);
      }
    }
  };
  useEffect(() => {
    const allowedOrigins = [ExternalDynamicLinks.dashboard()];
    // console.log("allowedOrigins", allowedOrigins);
    // const allowedOrigins = ["https://dev.signup.ticca.com"];
    if (window.addEventListener) {
      window.addEventListener('message', handleMessageFromSignupWeb, false);
    } else {
      window.attachEvent('message', (e) => {
        if (allowedOrigins.includes(e.origin)) {
          // debugger
          const data = e?.data;
          if (data) {
            const msgData = JSON.parse(data);
            const ownershipuid = msgData?.ownershipuid;
            const hashKey = msgData?.hashKey;

            localStorage.setItem('ownership_uid', ownershipuid);
            localStorage.setItem('hash_key', hashKey);

            // const badUuid = msgData?.bad_uuid;
            // AuthLocalStorageServices.tokens.setBadUuid(badUuid);
          }
        }
      });
    }
    return () => window.removeEventListener('message', handleMessageFromSignupWeb);
  }, []);
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path={routerPaths.SIGN_IN} element={<Signin />} />

          <Route path={routerPaths.SIGN_UP} element={<MainComponent />} />
          <Route
            path={routerPaths.EMAIL_VERIFICATION}
            element={
              // <SignUpFlowRoute>
              <EmailVerification />
              // </SignUpFlowRoute>
            }
          />
          <Route
            path={routerPaths.ENTER_MOBILE_NUMBER}
            element={
              // <SignUpFlowRoute>
              <EnterMobileNumber />
              // </SignUpFlowRoute>
            }
          />
          <Route
            path={routerPaths.MOBILE_VERIFICATION}
            element={
              // <SignUpFlowRoute>
              <MobileVerification />
              // </SignUpFlowRoute>
            }
          />
          <Route
            path={routerPaths.SIGNUP_SPLASH_SCREEN}
            element={
              // <SignUpFlowRoute>
              <SplashScreen />
              // </SignUpFlowRoute>
            }
          />

          <Route path={routerPaths.FORGOT_PASSWORD_ENTER_EMAIL} element={<ForgotPassword />} />
          <Route
            path={routerPaths.FORGOT_PASSWORD_EMAIL_SENT}
            element={
              // <ForgotPasswordFlowRoute>
              <EmailSent />
              // </ForgotPasswordFlowRoute>
            }
          />
          <Route
            path={routerPaths.FORGOT_PASSWORD_ENTER_OTP}
            element={
              // <ForgotPasswordFlowRoute>
              <EnterOTP />
              // </ForgotPasswordFlowRoute>
            }
          />
          <Route
            path={routerPaths.FORGOT_PASSWORD_SET_NEW_PASSWORD}
            element={
              // <ForgotPasswordFlowRoute>
              <SetNewPassword />
              // </ForgotPasswordFlowRoute>
            }
          />

          <Route path={routerPaths.DOWNLOAD} element={<DownloadPage />} />

          <Route path={routerPaths.HOME} element={<Home />} />

          <Route path={routerPaths.ALLOW_REGISTER} element={<AllowRegister />} />
          <Route path={routerPaths.Re_REGISTRATION} element={<ReRegistration />} />
          <Route path={routerPaths.Re_REGISTRATIONAPPROVED} element={<ReRegistrationApproved />} />

          {/* <Route path={routerPaths.Add_OWNER_DEVICE} element={<AddOwnerDevice />} /> */}
          <Route path={routerPaths.TERM_AND_CONDITIONS} element={<TermAndConditions />} />
          <Route path={routerPaths.LEGAL_PRIVACY_POLICY} element={<LegalPrivacyPolicy />} />

          {/* <Route path="*" re element={<PageNotFound />} /> */}

          <Route path="*" element={<Navigate to={routerPaths.SIGN_UP} replace={true} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
