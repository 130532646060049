const env = process.env?.REACT_APP_STAGE_ENV;

export class PolicyLinks {
  static terms = (): string => {
    let url = 'https://www.ticca.com/policy/terms';

    if (env === 'dev' || process.env?.NODE_ENV === 'development') {
      url = 'https://dev.www.ticca.com/policy/terms';
    }

    if (env === 'stage') {
      url = 'https://stage.www.ticca.com/policy/terms';
    }
    return url;
  };

  static privacy = (): string => {
    let url = 'https://www.ticca.com/policy/privacy';

    if (env === 'dev' || process.env?.NODE_ENV === 'development') {
      url = 'https://dev.www.ticca.com/policy/privacy';
    }

    if (env === 'stage') {
      url = 'https://stage.www.ticca.com/policy/privacy';
    }
    return url;
  };
}

export class ExternalDynamicLinks {
  static dashboard = (): string => {
    const env = process.env?.REACT_APP_STAGE_ENV;

    let url = 'https://dev.dashboard.ticca.com/';

    if (process.env?.NODE_ENV === 'development') {
      url = 'https://localhost:3000';
    }

    if (env === 'dev') {
      url = 'https://dev.dashboard.ticca.com';
    }

    if (env === 'stage') {
      url = 'https://stage.dashboard.ticca.com';
    }

    return url;
  };

  static getPublicContent = (): string => {
    const env = process.env?.REACT_APP_STAGE_ENV;

    let url = 'https://dev.api.ticca.com/cms-management/public-content';

    if (process.env?.NODE_ENV === 'development') {
      url = 'https://dev.api.ticca.com/cms-management/public-content';
    }

    if (env === 'dev') {
      url = 'https://dev.api.ticca.com/cms-management/public-content';
    }

    if (env === 'stage') {
      url = 'https://stage.api.ticca.com/cms-management/public-content';
    }

    if (env === 'prod') {
      url = 'https://api.ticca.com/cms-management/public-content';
    }

    return url;
  };

  static login = (): string => {
    const env = process.env?.REACT_APP_STAGE_ENV;

    let url = 'https://dev.login.ticca.com/logout';

    if (process.env?.NODE_ENV === 'development') {
      url = 'https://dev.login.ticca.com/logout';
    }

    if (env === 'dev') {
      url = 'https://dev.login.ticca.com/logout';
    }

    if (env === 'stage') {
      url = 'https://stage.login.ticca.com/logout';
    }

    if (env === 'prod') {
      url = 'https://login.ticca.com/logout';
    }

    return url;
  };
}
