import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../styles/styles.scss';

export class Toast {
  static toastConfig: any = {
    autoClose: 5000,
    closeOnClick: true,
    position: 'bottom-center',
    hideProgressBar: true,
    className: 'toast-container custom-toast toast-container-above-form' // Add 'custom-toast' class name
  };

  static success = (message: string): any => {
    const successConfig = { ...this.toastConfig, className: 'toast-container success-message' };
    return toast.success(message, successConfig);
  };

  static error = (message: string): any => {
    const errorConfig = { ...this.toastConfig, className: 'toast-container error-message' };
    return toast.error(message, errorConfig);
  };

  static warning = (message: string): any => {
    const warningConfig = { ...this.toastConfig, className: 'toast-container warning-message' };

    return toast.warning(message, warningConfig);
  };
}
