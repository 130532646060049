import { put, takeEvery } from 'redux-saga/effects';
import { signupActionTypes } from './signupActions';
import { Axios } from '../../../utils/axios';
import { routerPaths } from '../../../utils/routes';
import { signUpErrorHandler } from '../../../helpers/errorHandling';
import { jsonToForm } from '../../../helpers/helperFunctions';
import { toast } from 'react-toastify';
import { Constants, LocalStorageConstants } from '../../../utils/constants';
import { Toast } from '../../../utils/toast';

export function* signUp({ values, navigate }) {
  try {
    const formData = jsonToForm(values);
    const response = yield Axios.post('/signup', formData);
    const tempUser = {
      contact_uid: response?.data.contact_uid,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      country: values.country
    };

    if (response?.data?.['error_code']) {
      let message = 'Something went wrong while registering user details!';

      if (
        response?.data?.['error_code'] === 5 ||
        response?.data?.message === 'You are already registered with us, please try signing in'
      ) {
        Toast.warning('You are already registered with us, please try to signing in');
        yield put({ type: signupActionTypes.SIGN_UP_REQUEST_END, tempUser });
        navigate(routerPaths.SIGN_IN);
        return;
      }
      if (response?.data?.message) {
        message = response?.data?.message;
      }
      Toast.warning(response?.data?.message);

      let messageArray = [];
      messageArray.push(message);
      yield put({ type: signupActionTypes.SIGN_UP_FAILED, error: { message: messageArray } });
      return;
    }

    if (response?.data.status === 1) {
      Toast.success(response?.data?.message);
      localStorage.clear();
      localStorage.setItem(LocalStorageConstants.contactUid, response?.data?.contact_uid ?? '');
      localStorage.setItem('hash_key', response?.data?.['hash_key'] ?? '');
      localStorage.setItem('expiry', response?.data?.['expiry'] ?? '');
      yield put({ type: signupActionTypes.SIGN_UP_SUCCESS, tempUser });
      yield put({
        type: signupActionTypes.SET_FLOW_MANAGEMENT,
        values: { currentFlowPath: routerPaths.EMAIL_VERIFICATION, timestamp: new Date() }
      });

      navigate(routerPaths.EMAIL_VERIFICATION);
    }
  } catch (error) {
    yield put({
      type: signupActionTypes.SIGN_UP_FAILED,
      error: signUpErrorHandler(
        error?.response?.data ?? { message: 'Something went wrong while registering user details!' }
      )
    });
  }
}

export function* emailVerification({ values, navigate }) {
  try {
    const formData = jsonToForm(values);
    const response = yield Axios.post('/verify/email', formData);
    if (response?.data?.['error_code']) {
      Toast.error(response?.data?.message);
      yield put({
        type: signupActionTypes.EMAIL_VERIFICATION_FAILED,
        error: signUpErrorHandler(
          response?.data ?? {
            message: 'Something went wrong while verifying your email address!'
          }
        )
      });
      return;
    }
    const { status, signin_hash_key } = response.data;
    if (!status || status !== 1) {
      Toast.error(response?.data?.message);
      yield put({
        type: signupActionTypes.EMAIL_VERIFICATION_FAILED,
        error: signUpErrorHandler(
          response?.data ?? {
            message: 'Something went wrong while verifying your email address!'
          }
        )
      });
    } else {
      Toast.success(response?.data?.message);
      localStorage.setItem('signin_hash_key', signin_hash_key ?? '');
    }
    yield put({
      type: signupActionTypes.EMAIL_VERIFICATION_SUCCESS
    });

    yield put({
      type: signupActionTypes.SET_FLOW_MANAGEMENT,
      values: { currentFlowPath: routerPaths.SIGN_IN, timestamp: new Date() }
    });

    navigate(routerPaths.SIGN_IN);
  } catch (error) {
    yield put({
      type: signupActionTypes.EMAIL_VERIFICATION_FAILED,
      error: signUpErrorHandler(
        error?.response?.data ?? {
          message: 'Something went wrong while verifying your email address!'
        }
      )
    });
  }
}

export function* resendEmail({ values }) {
  try {
    const formData = jsonToForm(values);
    const response = yield Axios.post('/resend/signup/email', formData);
    if (response?.data?.['error_code']) {
      yield put({
        type: signupActionTypes.EMAIL_VERIFICATION_FAILED,
        error: signUpErrorHandler(
          response?.data ?? {
            message: 'Something went wrong while re sending verification code!'
          }
        )
      });
      return;
    }
    const { status } = response.data;
    if (!status && status !== 1) {
      Toast.error(response?.data?.message);
      yield put({
        type: signupActionTypes.EMAIL_VERIFICATION_FAILED,
        error: signUpErrorHandler(
          response?.data ?? {
            message: 'Something went wrong while re sending verification code!'
          }
        )
      });
    } else {
      if (response?.data) {
        const { hash_key, ownership_uid, expiry } = response.data;

        if (hash_key) localStorage.setItem('hash_key', hash_key);
        if (ownership_uid) localStorage.setItem('ownership_uid', ownership_uid);
        if (expiry) localStorage.setItem('expiry', expiry);
      }
      Toast.success(response?.data?.message);
      yield put({
        type: signupActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESS
      });
    }
  } catch (error) {
    toast.error(signUpErrorHandler(error.response.data).message.join(','));
  }
}

function* watchHomePageActionSagas() {
  yield takeEvery(signupActionTypes.SIGN_UP, signUp);
  yield takeEvery(signupActionTypes.EMAIL_VERIFICATION, emailVerification);
  yield takeEvery(signupActionTypes.RESEND_EMAIL_VERIFICATION, resendEmail);
}

export default [watchHomePageActionSagas];
